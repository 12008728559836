<template>
  <v-app :style="showNavAndFooter ? 'overflow-y: hidden;' : ''">
    <NavBar v-if="showNavAndFooter" />
    <v-main class="main-container">
      <v-container fluid>
        <v-row>
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer v-if="showNavAndFooter" />
  </v-app>
</template>

<script>
import NavBar from './shared/NavBar.vue';
import Footer from './shared/Footer.vue';

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = process.env.VUE_APP_APP_NAME;
      }
    }
  },
  computed: {
    showNavAndFooter() {
      return this.$route.path !== '/';
    }
  },
  components: {
    NavBar,
    Footer
  }
}
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.v-main {
  flex: 1;
  display: flex;
  margin-left: var(--drawer-width);
}

.main-container {
  width: 100%;
  background-color: var(--site-bg-color) !important;
}

#footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.v-carousel__controls{
  background: transparent !important;
}
</style>
