import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import './global.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

loadFonts()

createApp(App)
	.use(router)
	.use(vuetify)
	.use(Toast)
	.use(VueGoogleMaps, {
		load: {
			key: 'AIzaSyBn-QfE0lmL6i2uenW2aDpirqsT-La3jTA',
			libraries: 'places',
		},
	})
	.mount('#app')
