<template>
	<v-app-bar id="navbar" elevation="2" app>
		<v-app-bar-nav-icon @click.stop="toggleDrawer" v-if="isMobile"></v-app-bar-nav-icon>
		<div v-if="!isMobile" style="vertical-align: middle">
			<v-img style="cursor: pointer;" @click="navigate('/home')" src="../../public/favicon.png" width="50px"
				class="m-2" />
		</div>
		<div v-if="!isMobile" class="d-flex">
		</div>
	</v-app-bar>

	<v-navigation-drawer id="navbar" v-model="drawer" :permanent="!isMobile" :temporary="isMobile" app class="drawer">
		<v-list>
			<v-row v-if="isMobile">
				<v-col>
					<v-img src="../../public/favicon.png" width="50px" class="m-2" />
				</v-col>
			</v-row>
			<template v-for="(item, index) in items" :key="index">
				<v-list-item v-if="!item.sous_objects || item.sous_objects.length === 0" :to="item.path"
					:class="{ highlight: isActive(item.path) }" @click="navigate(item.path)">
					<v-list-item-title>{{ item.name }}</v-list-item-title>
				</v-list-item>

				<v-list-item v-else @click="toggleSubItems(index)">
					<div style="display: flex !important">
						<v-list-item-title>{{ item.name }}</v-list-item-title>
						<v-icon>{{ expandedIndex === index ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
					</div>
				</v-list-item>

				<v-list-item v-for="(subItem, subIndex) in item.sous_objects" :key="subIndex"
					v-if="expandedIndex === index" :to="subItem.path" :class="{ highlight: isActive(subItem.path) }"
					@click="navigate(subItem.path)" class="ml-4">
					<v-list-item-title>{{ subItem.name }}</v-list-item-title>
				</v-list-item>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	name: "NavBar",
	data() {
		return {
			drawer: true,
			isMobile: false,
			expandedIndex: null,
			menuOpened: false,
			items: [
				{
					name: "Accueil",
					path: "/home",
				},
				{
					name: "Projets",
					path: "/projets",
					sous_objects: [
						{ name: "Maisons", path: "/projets/maisons" },
						{ name: "Extensions", path: "/projets/extensions" },
						{ name: "Equipements", path: "/projets/equipements" },
						{ name: "Commerces", path: "/projets/commerces" },
						{ name: "Logements collectifs", path: "/projets/collectifs" },
						{ name: "Urbanisme", path: "/projets/urbanismes" },
					],
				},
				{ name: "Agence", path: "/agence" },
				{ name: "Missions", path: "/missions" },
				{ name: "Contact", path: "/contact" },
			],
			currentPath: "",
		};
	},
	mounted() {
		this.checkScreenWidth();
		window.addEventListener("resize", this.checkScreenWidth);

		this.currentPath = this.$route.path;
		this.$router.afterEach((to, from) => {
			this.currentPath = to.path;
			this.menuOpened = false;
			if (to.path.startsWith("/projets")) {
				this.drawer = true;
				this.expandParentItem("/projets");
			}
		});
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.checkScreenWidth);
	},
	methods: {
		checkScreenWidth() {
			this.isMobile = window.innerWidth <= 700;
			if (!this.isMobile) {
				this.drawer = true;
			}
		},
		isActive(path) {
			return this.currentPath.includes(path);
		},
		toggleDrawer() {
			if (this.isMobile) {
				this.drawer = !this.drawer;
			}
		},
		navigate(path) {
			this.menuOpened = false;
			if (this.isMobile) {
				this.drawer = false;
			}
			this.$router.push(path);
		},
		toggleSubItems(index) {
			this.expandedIndex = this.expandedIndex === index ? null : index;
		},
		expandParentItem(path) {
			const parentIndex = this.items.findIndex((item) => item.path === path);
			if (parentIndex !== -1) {
				this.expandedIndex = parentIndex;
			}
		},
	},
};
</script>

<style scoped>
.drawer {
	width: var(--drawer-width);
}

@media (max-width: 700px) {
	.drawer {
		width: auto;
	}
}

.highlight {
	color: white;
}

.ml-4 {
	margin-left: 16px;
}

#navbar {
	background-color: rgb(150, 150, 150);
}
</style>