import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Index',

		component: () => {
			return import('@/views/Index.vue')
		}
	},
	{
		path: '/home',
		name: 'Home',

		component: () => {
			return import('@/views/Home.vue')
		}
	},
	{
		path: '/projets/:category?',
		name: 'Projets',

		component: () => {
			return import('@/views/Projects.vue')
		}
	},
	{
		path: "/agence",
		name: 'Agence',

		component: () => {
			return import('@/views/Agence.vue')
		}
	},
	{
		path: "/contact",
		name: "Contact",

		component: () => {
			return import("@/views/Contact.vue")
		}
	},
	{
		path: "/agence",
		name: "Agence",

		component: () => {
			return import("@/views/Agence.vue")
		}
	},
	{
		path: "/missions",
		name: "Missions",

		component: () => {
			return import("@/views/Missions.vue")
		}
	},
	{
		path: '/:catchAll(.*)',
		name: "NotFound",

		component: () => {
			return import("@/views/errors/NotFound.vue")
		}
	},
	{
		path: '/projet/:id',
		name: 'ProjetDetail',
		component: () => import('@/views/ProjetDetail.vue'),
		props: true
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router