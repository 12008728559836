<template>
	<footer class="elevation-4 bg-white text-black text-center py-1">
		<p class="mb-0">&copy; {{ new Date().getFullYear() }} {{ appName }}. Tous droits réservés.</p>
	</footer>
</template>

<script>
export default {
	name: 'Footer',
	data() {
		return {
			appName: process.env.VUE_APP_APP_NAME
		}
	}
};
</script>

<style scoped>
footer p {
	margin: 0;
}

footer {
	background-color: var(--site-bg-color) !important;
}

.elevation-2 {
	box-shadow:
		0px 3px 1px -3px var(--v-shadow-key-umbra-opacity,
			rgba(0, 0, 0, 0.3)),
		0px 3px 3px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
		0px 1px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12)) !important;
}
</style>